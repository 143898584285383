import { createMuiTheme } from '@material-ui/core/styles'
//2 nouvelles couleurs 16/04/2024 =>  green = '#B5DCCD' parme = '#AB93C5'
const white = '#fff'
const black = '#000'
const primaryMain = '#AB93C5' //parme #AB93C5    (#f49cb1 = rose ancienne couleur)
const primaryMain_light = '#ab93c51a' //parme #AB93C5 avec 10% opacité
const primaryDark = '#f07a95'
const primaryLight = '#F6F4F8' //ancienne couleur rose clair #FEF6F9 >> remplacée violet clair #F6F4F8
const secondaryMain = '#B5DCCD' //green #AB93C5 (#f49cb1 = rose ancienne couleur)
const secondaryDark = '#f07a95'
const greyLight = '#F8F8F8'

const imp = '!important'
const headerHeightMobile = '50px'

const buttonClassic = {
  color: `${black} !important`,
  backgroundColor: `${white} !important`,
  border: `1px solid ${primaryMain}  !important`,
  '&:hover, &:focus': {
    color: `${white} !important`,
    backgroundColor: `${primaryMain} !important`,
  },
}

const buttonPriority = {
  color: `${white} !important`,
  backgroundColor: `${black} !important`,
  border: `none !important`,
  '&:hover, &:focus': {
    color: `${white} !important`,
    backgroundColor: `${black} !important`,
  },
}

const title10 = {
  fontSize: '10px !important',
  letterSpacing: '0.6px !important',
  lineHeight: '1.9 !important',
  fontWeight: '500 !important',
}

const title11 = {
  textTransform: 'uppercase !important',
  fontWeight: '600 !important',
  fontSize: '11px !important',
  letterSpacing: '1.1px !important',
}

const title12 = {
  fontSize: '12px!important',
  letterSpacing: '1.2px!important',
}

const title14 = {
  fontSize: '14px! important',
  letterSpacing: '1.4px !important',
  textTransform: 'uppercase !important',
  fontWeight: '600 !important',
}

const title32 = {
  fontSize: '32px! important',
  letterSpacing: '3.3px !important',
  textTransform: 'uppercase !important',
  fontWeight: '600 !important',
  color: `${primaryMain} !important`,
}

const titleWithLogo = {
  color: primaryMain,
  fontSize: 22,
  letterSpacing: '1.9px',
  textTransform: 'uppercase',
  '&:after': {
    content: '""',
    display: 'inline-block',
    verticalAlign: '-20%',
    background:
      'url("https://images.prismic.io/lizee-maje/ZkSJYSol0Zci9J7b_cloverrseweb.png?auto=format,compress") no-repeat 50% 50%',
    //images/maje/icon_maje_arrow.svg
    backgroundSize: 'contain',
    width: 43,
    height: 40,
    marginLeft: 25,
  },
}

const customSelect = {
  fontSize: `14px ${imp}`,
  border: `1px solid ${greyLight} ${imp}`,
  borderRadius: `6px ${imp}`,
  padding: `8px 10px ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: 'AvtGarde',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'AvtGarde', sans-serif",
    h1: {
      color: `${primaryMain}`,
      fontSize: 33,
      lineHeight: 1.15,
      fontWeight: 600,
      letterSpacing: '4.9px',
    },
    h2: {
      color: `${primaryMain}`,
      fontSize: 28,
      lineHeight: 1.15,
      fontWeight: 700,
      letterSpacing: '2.8px',
    },
    h3: {
      color: `${primaryMain}`,
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 600,
      letterSpacing: '1.4px',
      textTransform: 'uppercase',
    },
    h4: {
      color: `${primaryMain}`,
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 600,
      letterSpacing: '1.4px',
      textTransform: 'uppercase',
    },
    h5: {
      color: `${primaryMain}`,
      fontSize: 12,
      letterSpacing: '1.2px',
      lineHeight: 1.25,
      fontWeight: 500,
    },
    h6: {
      color: `${primaryMain}`,
      fontSize: 11,
      letterSpacing: '1.1px',
      lineHeight: 1.25,
      fontWeight: 500,
    },
    body1: {
      fontSize: 12,
      letterSpacing: '1.8px',
      lineHeight: 1.6,
      fontWeight: 500,
    },
    body2: {
      fontSize: 12,
      letterSpacing: '1.8px',
      lineHeight: 1.6,
      fontWeight: 500,
    },
    caption: {
      fontSize: 10,
      letterSpacing: '0.6px',
      lineHeight: 1.9,
      fontWeight: 500,
    },
    button: {
      fontSize: 12,
      letterSpacing: '1.3px',
      lineHeight: 1.4,
      fontWeight: 600,
    },
  },
  palette: {
    common: {
      black: black,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      main: secondaryMain,
      dark: secondaryDark,
      contrastText: white,
    },
    success: {
      main: '#000',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: '#000',
    primaryTransparent: {
      main: black,
      light: 'transparent',
    },
    secondaryTransparent: {
      main: black,
      light: 'transparent',
    },
    ternary: {
      main: primaryMain,
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 77,
    headerHeightTransparent: 77,
    logoWidth: 140,
    logoHeight: 37,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: black,
    greyLight: greyLight,
    greyMedium: '#575859',
    greyText: '#575859',
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: `${primaryLight}!important`,
        },
        '.fade': {
          opacity: '1 !important',
        },
        '.select': {
          ...customSelect,
        },
        '.react-datepicker': {
          borderRadius: '0!important',
        },
        '.react-datepicker__navigation::before, .react-datepicker__navigation::after':
          {
            backgroundColor: `${black}!important`,
          },
        '.react-datepicker__day--in-range, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
          {
            color: `${black}!important`,
          },
        '.react-datepicker-popper>div>div>div+div': {
          backgroundColor: `${primaryMain}!important`,
          '&>div': {
            color: `${white}!important`,
          },
        },
        '.react-datepicker-wrapper': {
          '@media (min-width: 768px)': {
            width: '152px!important',
            textAlign: 'center',
          },
          '@media (min-width: 1366px)': {
            width: '190px!important',
          },
        },
        '#swiper-products__prev, #swiper-products__next': {
          '&>.MuiButton-label': {
            '&:before': {
              content: '""',
              background:
                'url("./images/maje/icon_arrow_left.svg") no-repeat 50% 50%',
              width: 24,
              height: 24,
            },
            '& svg': {
              display: 'none',
            },
          },
          '&:hover, &:focus': {
            background: 'none !important',
          },
        },
        '#swiper-products__next': {
          '&>.MuiButton-label': {
            '&:before': {
              background:
                'url("./images/maje/icon_arrow_right.svg") no-repeat 50% 50%',
            },
          },
        },
        '.banner': {
          height: 'auto !important',
          minHeight: '34px !important',
          background: `${black}!important`,
          padding: '12px 16px !important',
          '& .MuiTypography-root': {
            color: `${white}!important`,
            ...title11,
          },
          '& .paragraph-class': {
            margin: '0',
            textTransform: 'none !important',
            lineHeight: '1.4 !important',
            letterSpacing: '1.9px !important',
            fontWeight: '600 !important',
          },
        },
        '.header': {
          '&--top': {
            background: 'transparent !important',
            zIndex: 1000,
          },
          '&__layout': {
            '@media (max-width: 1024px)': {
              height: '52px !important',
            },
          },
          '&__right': {
            border: '0 !important',
          },
          '&__logo': {
            height: '23px!important',
            '@media (min-width: 480px)': {
              height: '30px!important',
              //marginLeft: '10px !important',
              //marginTop: '6px !important',
            },
          },
          '&__icons': {
            borderLeft: `0 ${imp}`,
            '@media (min-width: 1024px)': {
              flexDirection: `row-reverse ${imp}`,
            },
          },
          '&__burger': {
            marginLeft: `16px ${imp}`,
            width: `24px ${imp}`,
            color: `${black} ${imp}`,
            background: `url("/images/maje/burger.svg") no-repeat 50% 50% ${imp}`,
            backgroundSize: `contain ${imp}`,
            '& path': {
              display: `none ${imp}`,
              visibility: `hidden ${imp}`,
            },
          },
          '&__cta, &__lang': {
            '@media (min-width: 1366px)': {
              marginLeft: `20px ${imp}`,
            },
          },
          '&__langbtn': {
            '&[aria-current=true]': {
              fontWeight: 500,
            },
          },
          '&__cta, &__langbtn, &__extraLink': {
            ...title11,
            '@media (min-width: 1024px)': {
              fontSize: `0 ${imp}`,
            },
            '& .MuiSvgIcon-root, & .MuiButton-label': {
              width: `20px ${imp}`,
              height: `20px ${imp}`,
            },
            '& .MuiSvgIcon-root': {
              marginRight: `0 ${imp}`,
              '&>path': {
                display: `none ${imp}`,
                visibility: `hidden ${imp}`,
              },
            },
            '.header--transparent &': {
              color: `${black} ${imp}`,
            },
            '&--account': {
              '& .MuiSvgIcon-root': {
                background: `url("/images/maje/icon_account_blk.svg") no-repeat 50% 50% ${imp}`,
                backgroundSize: `contain ${imp}`,
              },
              '& .MuiSvgIcon-root, & .MuiButton-label': {
                height: `20px ${imp}`,
              },
              // '.header--transparent &': {
              //   background: `url("/images/maje/icon_account_blk.svg") no-repeat 0 50% ${imp}`,
              // },
            },
            '&--faq': {
              '& .MuiSvgIcon-root': {
                background: `url("/images/maje/icon_faq_blk.svg") no-repeat 0 50% ${imp}`,
                backgroundSize: `contain ${imp}`,
              },
              '& .MuiSvgIcon-root, & .MuiButton-label': {
                width: `24px ${imp}`,
              },
              // '.header--transparent &': {
              //   background: `url("/images/maje/icon_faq_blk.svg") no-repeat 0 50% ${imp}`,
              // },
            },
          },
          '&__extraLink': {
            '& .MuiTypography-srOnly': {
              position: 'static',
              overflow: 'inherit',
              width: 'inherit',
              height: 'inherit',
              '@media (max-width: 480px)': {
                display: 'none',
              },
            },
          },
          '&__menu': {
            '@media (max-width: 1024px)': {
              display: 'flex',
            },
          },
        },
        '.menu__item': {
          fontSize: `12px !important`,
        },
        '.menu': {
          '.header--transparent &': {
            color: `${black} ${imp}`,
          },
          '&__link': {
            textTransform: `uppercase ${imp}`,
            letterSpacing: `1px ${imp}`,
            '.header--transparent &': {
              color: `${black} ${imp}`,
            },
            '&[target="_blank"]': {
              fontWeight: 700,
            },
            '@media (min-width: 1024px)': {
              '&:hover, &:focus': {
                textDecoration: 'underline',
              },
            },
          },
          '&__list': {
            '&--primary': {
              '@media (min-width: 1024px)': {
                gap: `24px ${imp}`,
                whiteSpace: `nowrap ${imp}`,
                '& .menu__item:after': {
                  display: 'none',
                },
              },
              '@media (min-width: 1366px)': {
                gap: `45px ${imp}`,
              },
            },
          },
          '&--mobile': {
            border: `0 ${imp}`,
          },
          '&-mobile': {
            '&__header': {
              height: `${headerHeightMobile} ${imp}`,
              borderBottom: `0 ${imp}`,
            },
            '&__close': {
              fontSize: `0 ${imp}`,
            },
            '&__back': {
              top: `${headerHeightMobile} ${imp}`,
              background: `transparent ${imp}`,
            },
            '&__content': {
              marginTop: `${headerHeightMobile} ${imp}`,
              background: `${white} ${imp}`,
            },
            '&__item': {
              '&--primary': {
                fontSize: `15px ${imp}`,
                textTransform: `uppercase ${imp}`,
                fontWeight: `400 ${imp}`,
                borderBottom: `0 ${imp}`,
                paddingTop: `17.5px ${imp}`,
                paddingBottom: `17.5px ${imp}`,
              },
            },
            '&__footer': {
              background: `${primaryLight} ${imp}`,
              padding: `40px 16px ${imp}`,
              display: `flex ${imp}`,
              flexDirection: `column ${imp}`,
              gap: `23px ${imp}`,
            },
            '&__cta': {
              color: `${black} ${imp}`,
              background: `${primaryLight} ${imp}`,
              padding: `0 ${imp}`,
              fontWeight: `400 ${imp}`,
              fontSize: `12px ${imp}`,
              textTransform: `none ${imp}`,
              '& .MuiSvgIcon-root': {
                display: `none ${imp}`,
              },
            },
          },
        },
        '.search__button__icon__only__transparent': {
          color: '#000',
        },
        '.search__button__icon': {
          borderRadius: `16px ${imp}`,
          textTransform: `capitalize`,
          height: '20px',
          '& > span': {
            fontWeight: `400 ${imp}`,
            fontSize: `12px ${imp}`,
          },
          '& span span:first-child': {
            marginRight: '20px',
          },
        },
        '.search__button__icon__transparent': {
          border: `1px solid ${black} !important`,
          '& > span': {
            color: `${black} !important`,
          },
        },
        '.search_input_container': {
          borderRadius: '18px',
          backgroundColor: '#F0F0F0!important',
          letterSpacing: '1px',
        },
        '.search_input_icon_button': {
          borderRadius: '14px!important',
        },
        '.main': {
          '&--shop, &--pack': {
            // zIndex: 1101, // VS header
            // position: 'relative',
          },
        },
        '.hero__home': {
          background: `${primaryLight} !important`,
          '@media (max-width: 1024px) and (max-height: 667px)': {
            minHeight: '452px !important',
          },
          '&>video': {
            backgroundColor: `${primaryLight} !important`,
            height: '100%',
            //fix weird border on safari iOS
            '@media (max-width: 1024px) and (max-height: 667px)': {
              height: 452, // === header Mobile height and and display section-menu above the fold
            },
          },
          '& .wrapper': {
            minHeight: 'calc(100vh - 195px)', // Display section-menu above the fold
            '@media only screen and (max-width: 1024px) and (max-height: 667px)':
              {
                minHeight: 400, // header height minus banner and display section-menu above the fold
              },
          },
          '& .layout': {
            '@media (max-width: 1024px)': {
              paddingTop: '25px !important',
            },
          },
          '&.left': {
            '& .MuiGrid-root.layout > .MuiGrid-grid-md-6': {
              '@media (min-width: 768px)': {
                maxWidth: '66.666667%',
                flexBasis: '66.666667%',
                '&>div': {
                  paddingLeft: 110,
                  marginTop: -32,
                },
              },
            },
          },
          '& .tagline': {
            color: `${white} !important`,
            fontSize: '15px !important',
            letterSpacing: '2.2px',
            '& p': {
              margin: 0,
            },
            '@media (max-width: 1024px)': {
              marginBottom: '0 !important',
            },
          },
          '& .title': {
            '@media (min-width: 1366px)': {
              fontSize: 33,
            },
            '@media (max-width: 1024px)': {
              marginBottom: '35px !important',
            },
          },
          '& .daterange': {
            border: '0!important',
            '@media (max-width: 767px)': {
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
            },

            '&__submit': {
              ...buttonPriority,
            },
          },
          '& .date_form': {
            '@media (max-width: 1024px)': {
              marginTop: '0 !important',
              paddingTop: '10px !important',
              '& [data-testid=submitDates]': {
                width: 'auto',
              },
            },
          },
        },
        '.sizes': {
          alignItems: 'baseline !important',
          '&>.MuiTypography-h6': {
            ...title12,
            fontWeight: '500 !important',
          },
          '&__btn': {
            borderRadius: '5px !important',
            border: `1px solid ${primaryMain} !important`,
            minWidth: '24px !important',
            height: '24px !important',
            fontSize: '12px !important',
            padding: '0 8px !important',
            lineHeight: '1 !important',
            background: 'transparent !important',
            '&[disabled]': {
              borderColor: `#bdbdbd !important`,
              color: `#bdbdbd !important`,
            },
            '&:hover, &:focus, &.selected': {
              background: `${primaryMain} !important`,
              borderColor: `${primaryMain} !important`,
              color: `${white} !important`,
            },
          },
          '&__chip': {
            top: '-16px!important',
            right: '-20px!important',
          },
        },
        '.size-card': {
          '&__title': {
            ...title14,
            fontWeight: '700 !important',
          },
          '&__top, &__bottom': {
            padding: 16,
          },
        },
        '.quantitySelector': {
          '&__total': {
            borderRadius: '0!important',
            width: '32px !important',
            height: '32px !important',
            '&> span': {
              top: 1,
              position: 'relative',
            },
            '&--small': {
              width: '22px !important',
              height: '22px !important',
            },
          },
          '&__btn>span': {
            top: 2,
          },
        },
        '.size-modal': {
          '&--pack': {
            '& .MuiPaper-root': {
              '@media (min-width: 1024px)': {
                maxWidth: 1085,
                width: '100%',
              },
            },
          },
          '&--product': {
            '& .MuiPaper-root': {
              '@media (min-width: 1024px)': {
                maxWidth: 660,
                width: '100%',
                '@media (max-height: 1024px)': {},
              },
            },
          },
          '& .MuiDivider-root, &__head, &__foot': {
            background: 'none !important',
          },
          '&__head, &__foot': {
            '@media (min-width: 1366px)': {
              marginRight: 36,
              marginLeft: 36,
            },
          },
          '&__title': {
            '&.MuiTypography-root': {
              ...title14,
              '@media (min-width: 1366px)': {
                marginTop: 45,
              },
            },
          },
          '&__close': {
            '&.MuiSvgIcon-root': {
              backgroundColor: white,
              color: black,
              '@media (min-width: 1366px)': {
                marginTop: 40,
              },
            },
          },
          '&__content': {
            border: '0 !important',
          },
        },
        '.shop-menu': {
          '&__cta': {
            '&-link': {
              minWidth: 160,
              ...buttonClassic,
              /*  
              background: `${primaryMain} !important`,
              color: `${white} !important`,
              border: `0 !important`,
              
              '&:hover, &:focus': {
                background: `${primaryDark} !important`,
              },
              */
              '& .MuiButton-endIcon.MuiButton-iconSizeSmall, & .MuiSvgIcon-root':
                {
                  display: 'none',
                },
            },
          },
          '&--products,&--pack': {
            '@media (max-width: 1024px)': {
              display: 'flex !important',
              flexWrap: 'wrap',
              gap: 8,
              '& .shop-menu__cta': {
                textAlign: 'center !important',
                marginBottom: '0 !important',
                paddingTop: '0 !important',
                '&-link': {
                  fontSize: '11px !important',
                },
              },
            },
          },
          '&--pack': {
            '@media (max-width: 1024px)': {
              flexDirection: 'row !important',
              '& .shop-menu__cta': {
                marginBottom: '0 !important',
              },
            },
          },
          '& .shop-filters': {
            ...buttonClassic,
          },
        },
        '.pack': {
          '&__title, &__products-title, &-product-modal__title': {
            '& .MuiTypography-root': {
              ...title12,
              lineHeight: 1.6,
              fontWeight: 600,
              textTransform: 'uppercase',
            },
          },
          '&-product': {
            '&__foot': {
              paddingLeft: '0!important',
              paddingRight: '0!important',
            },
            '&__title': {
              ...title12,
              fontWeight: '400!important',
              textTransform: 'none !important',
              minHeight: '0!important',
            },
            '&-modal': {
              '&__title': {
                '&.MuiTypography-root': {
                  ...title12,
                  lineHeight: 1.6,
                  fontWeight: 600,
                  textTransform: 'uppercase',
                },
              },
              '&__desc': {
                ...title11,
                fontWeight: '500 !important',
                textTransform: 'inherit !important',
                lineHeight: '1.7 !important',
                '& *': {
                  marginTop: '0!important',
                  marginBottom: '30px !important',
                },
                '& ul, & ol': {
                  paddingLeft: '1em',
                  '& li': {
                    marginBottom: '0 !important',
                  },
                },
              },
            },
          },
          '&__by': {
            paddingLeft: '1ch',
            paddingRight: '1ch',
          },
          '&__price': {
            marginTop: '0!important',
            fontSize: '10px !important',
            fontWeight: '700 !important',
            color: `${black} !important`,
            letterSpacing: '1px!important',
            '&.MuiTypography-root': {
              fontWeight: 500,
            },
          },
          '&__grid': {
            '& > .MuiGrid-item:first-child': {
              order: 999, // keep first pack at the end of the list
            },
          },
        },
        '.packFashion': {
          '& .sliderNav, & .glider-next, & .glider-prev': {
            background: 'none !important',
          },
        },
        '.product, .productCard': {
          '&__by': {
            paddingLeft: '1ch',
            paddingRight: '1ch',
          },
        },
        '.productCard': {
          borderRadius: '0!important',
          '&__price': {
            marginTop: '0!important',
            fontSize: '10px !important',
            fontWeight: '700 !important',
            color: `${black} !important`,
            letterSpacing: '1px!important',
            '& .price__amount,& .price__duration,': {
              color: `${black} !important`,
            },
          },
          '&__title': {
            ...title12,
            fontWeight: '400!important',
            textTransform: 'none !important',
            minHeight: '0!important',
          },
          '&__desc': {
            padding: '12px 0!important',
          },
          '&__rowCta': {
            alignItems: 'center',
            '& .quantitySelector': {
              marginLeft: 16,
            },
            '&>div': {
              margin: 0,
            },
          },
          '&__cta': {
            marginLeft: 0,
            borderRadius: 0,
            color: `${black} !important`,
            border: `1px solid ${black} !important`,
            background: 'none!important',
            height: '24px!important',
            minWidth: '24px!important',
            '&:hover, &:focus': {
              background: `${black}!important`,
              color: `${white}!important`,
            },
            '&>span': {
              top: 0,
            },
            '& .MuiSvgIcon-root': {
              top: '0!important',
              width: '14px!important',
              height: '14px!important',
            },
          },
        },
        '.shop': {
          '&__planner': {
            '@media (min-width: 1366px)': {
              maxWidth: '1058px !important',
              borderRadius: '8px !important',
              '& .shipping-infos': {
                '&__start, &__end': {
                  minWidth: 190,
                },
              },
            },
            '@media (max-width: 1024px)': {
              marginTop: '-65px !important',
              minHeight: '110px !important',
              padding: '4px!important',
              '& .daterange': {
                justifyContent: 'space-between',
                padding: '0 !important',
                marginBottom: '6px !important',
                '& .MuiButton-root': {
                  padding: '10px 20px',
                  width: 'auto',
                },
              },
              '& .shipping-infos': {
                paddingBottom: '0 !important',
              },
              '& [data-testid=submitDates]': {
                display: 'none!important',
              },
              '& + .MuiContainer-root': {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },

            '& form': {
              '@media (min-width: 768px)': {
                gap: '8px !important',
              },
            },
          },
          '&__grid': {
            '@media (max-width: 1024px)': {
              marginTop: '0 !important',
            },
          },
        },
        '.product': {
          '&__container': {
            '@media (min-width: 1366px)': {
              maxWidth: '1440px !important',
              '&>.MuiContainer-root': {
                maxWidth: '1440px !important',
              },
            },
          },
          '&__main': {
            '@media (min-width: 1366px)': {
              marginLeft: '-48px !important',
            },
          },
          '&__left': {
            '@media (min-width: 1366px)': {
              paddingLeft: '0 !important',
            },
          },
          '&__right': {
            '@media (min-width: 1366px)': {
              maxWidth: '446px !important',
            },
          },
          '&__prev': {
            color: `${black} !important`,
            border: '0!important',
            padding: '0!important',
            fontWeight: 500,
            fontSize: '10px !important',
            '& .MuiButton-label': {
              textDecoration: 'underline',
              textTransform: 'none !important',
            },
          },
          '&__head': {
            flexWrap: 'wrap !important',
            '&-price': {
              textAlign: 'left !important',
            },
            '& .MuiGrid-item': {
              maxWidth: '100%',
              flexBasis: '100%',
              paddingTop: '4px !important',
              paddingBottom: '4px !important',
            },
            '@media (min-width: 1366px)': {
              paddingTop: 90,
              marginBottom: '0!important',
            },
          },
          '&__title, &__price': {
            textTransform: 'uppercase',
            fontWeight: '700 !important',
            fontSize: '15px !important',
            color: `${black} !important`,
            '& .price__amount,& .price__duration,': {
              color: `${black} !important`,
            },
          },
          '&__desc': {
            ...title11,
            fontWeight: '500 !important',
            textTransform: 'inherit !important',
            lineHeight: '1.7 !important',
            '& *': {
              marginTop: '0!important',
              marginBottom: '30px !important',
            },
            '& ul, & ol': {
              paddingLeft: '1em',
              '& li': {
                marginBottom: '0 !important',
              },
            },
          },
          '&__details': {
            marginTop: '0!important',

            '& .product__btn-addtocart': {
              ...buttonPriority,
            },
          },
          '&-tabs': {
            '& .MuiTabs-indicator': {
              height: '1px !important',
              backgroundColor: `${secondaryMain} ${imp}`,
            },
          },
          '&-tab': {
            '&.MuiTab-root': {
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
              marginLeft: 30,
              position: 'relative',
              overflow: 'inherit',
              fontSize: '10px !important',
              '&:first-child': {
                marginLeft: 0,
                '&:before': {
                  display: 'none',
                },
              },
              '& p': {
                textTransform: 'uppercase!important',
              },
              '&:before': {
                position: 'absolute',
                left: -15,
                top: '50%',
                marginTop: '-.5em',
                content: '""',
                height: '1em',
                width: 1,
                backgroundColor: `${secondaryMain}`,
              },
            },
          },
          '&-tabpanel': {
            '& .MuiBox-root': {
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
            },
          },
        },
        '.product-related': {
          '&__title': {
            ...title14,
          },
        },
        '.date_form': {
          background: 'none !important',
        },
        '.daterange': {
          border: `1px solid ${primaryMain}`,
          color: black,
          borderRadius: '5px !important',
          '@media (max-width: 1024px)': {
            border: 0,
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
            maxWidth: 320,
          },
          '&__icon': {
            color: `${black} !important`,
            top: 1,
            '&--calendar': {
              display: 'none !important',
            },
          },
          '& button': {
            fontWeight: 500,
            fontSize: '15px !important',
            letterSpacing: '2.2px',
            color: black,
          },
          '&__btn': {
            paddingTop: '11px !important',
            paddingBottom: '11px !important',
          },
          '&__submit': {
            ...buttonClassic,
          },
        },
        '.hiw': {
          '@media (max-width: 1024px)': {
            textAlign: 'center !important',
            padding: '16px 0px 8px !important',
          },
          '@media (min-width: 1366px)': {
            padding: '0 54px !important',
          },
          '&__head': {
            '@media (min-width: 480px)': {
              minHeight: '0 !important',
              paddingBottom: '0 !important',
            },
          },
          '&__title': {
            textTransform: 'uppercase',
            color: `${primaryMain} !important`,
            fontSize: '22px !important',
            letterSpacing: '2.3px !important',
            fontWeight: '500 !important',
          },
          '&__desc': {
            letterSpacing: '1.8px',
            lineHeight: 1.6,
          },
        },
        '.section': {
          '&__spacer': {
            padding: '32px 0!important',
            '@media (max-width: 1024px)': {
              padding: '6px 0!important',
            },
          },
        },
        '.section-hiw': {
          paddingTop: '0 !important',
          '&>.MuiContainer-root': {
            '@media (min-width: 1366px)': {
              maxWidth: '1230px !important',
            },
          },
          '&__spacing': {
            padding: '38px 0!important',
            '@media (max-width: 1024px)': {
              padding: '10px 0!important',
            },
          },
          '&__title': {
            textTransform: 'uppercase',
            paddingTop: '0 !important',
            '&>.MuiContainer-root': {
              marginTop: 'inherit !important',
            },
            '&>h1': {
              margin: '0 !important',
            },
          },
          '&__cta': {},
        },
        '.section-discover': {
          '&__subtext': {
            paddingBottom: '0 !important',
          },
          '&__subtitle': {
            marginLeft: '24px !important',
            '&>b': {
              ...title14,
            },
          },
          '&__desc': {
            marginLeft: '24px !important',
          },
          '& .swiper-slide': {
            marginBottom: '0 !important',
          },
        },
        '.section-menu': {
          padding: '16px 0 !important',
          '& li': {
            marginRight: '30px !important',
          },
          '&__link, &__cta': {
            color: `${black} !important`,
            ...title11,
            '&>span': {
              fontSize: '11px!important',
              fontWeight: '600!important',
            },
          },
          '&__link': {
            '&:hover, &:focus': {
              color: `${primaryMain} !important`,
            },
          },
          '&__cta': {
            whiteSpace: 'nowrap!important',
            '&:before': {
              content: '""',
              background:
                'url("./images/maje/icon_back.svg") no-repeat 50% 50%',
              backgroundSize: 'contain',
              display: 'inline-block',
              verticalAlign: '-15%',
              width: 10,
              height: 13,
              marginRight: 2,
              fontSize: '10px!important',
              '@media (min-width: 1024px)': {
                verticalAlign: '-20%',
                marginRight: 11,
                width: 16,
                fontSize: '11px!important',
              },
            },
          },
        },
        '#hero-sections': {
          '&>div': {
            minHeight: '0 !important',
          },
          '& .content': {
            color: black,
            maxWidth: 746,
            margin: 'auto',
            lineHeight: 2.3,
            letterSpacing: '1px',
            fontWeight: 500,
            '& h2': {
              ...titleWithLogo,
            },
            '& h2, & p': {
              '@media (min-width: 1024px)': {
                margin: '42px auto !important',
              },
            },
          },
          '& .section-hero__overlay': {
            display: 'none',
          },
          '& #section-hero': {
            '&--2': {
              minHeight: '100vh !important',
            },
          },
        },
        '.discover': {
          '@media (max-width: 1024px)': {
            '&:active, &:focus, &:hover': {
              opacity: 0.7,
              transition: 'opacity .25s ease',
            },
          },
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
            '&:before': {
              background: 'none !important',
            },
            '@media (max-width: 1024px)': {
              margin: '0 10px !important',
            },
          },
          '&__title': {
            position: 'static!important',
            padding: '16px 0 !important',
            background: 'none !important',
            color: `${black} !important`,
            textAlign: 'left!important',
            ...title11,
          },
        },
        '.discover-product': {
          '&__content': {
            position: 'static!important',
            padding: '16px 0 !important',
            background: 'none !important',
            color: `${black} !important`,
            textAlign: 'left!important',
            ...title11,
            '& .MuiTypography-root': {
              color: `${black} !important`,
            },
          },
        },
        '.shipping-infos': {
          '&__start, &__end': {
            '.cart-shipping.cart-shipping--absolute &': {
              background: `none !important`,
            },
            '.cart-shipping &': {
              background: `${greyLight}!important`,
            },
            '& .MuiTypography-body2': {
              ...title12,
              fontWeight: 500,
              letterSpacing: '1px',
              textTransform: 'uppercase !important',
              '.cart-shipping.cart-shipping--absolute &': {
                fontSize: '11px !important',
                letterSpacing: '1px',
                textTransform: 'none !important',
                marginLeft: '14px !important',
              },
              '.cart-shipping &': {
                fontSize: '11px !important',
                textTransform: 'none !important',
                fontWeight: 400,
                letterSpacing: '1px',
              },
              '@media (max-width: 1024px)': {
                fontSize: '9px !important',
                letterSpacing: '1px',
              },
            },
            '& .MuiTypography-body1': {
              ...title14,
              letterSpacing: '1.5px',
            },
          },
        },
        '.delivery': {
          justifyContent: 'flex-start !important',
        },
        '.deliveryDate, .returnDate': {
          ...title10,
          color: `${black} !important`,
          background: `${greyLight} !important`,
          marginLeft: 15,
          letterSpacing: 'normal !important',
          '&>strong': {
            color: `${primaryMain} !important`,
          },
        },
        '.deliveryTitle': {
          '&.MuiTypography-root': {
            color: `${primaryMain} !important`,
            textTransform: 'none',
            fontSize: 20,
            letterSpacing: '2px !important',
          },
        },
        '.label': {
          fontSize: '9px !important',
        },
        '.textField input, .textField select': {
          fontSize: '9px !important',
          letterSpacing: '1.8px !important',
          fontWeight: '500 !important',
        },
        '.faq': {
          '&__item': {
            marginBottom: '0!important',
            '&:last-child': {
              '& .faq__question': {
                borderBottom: '0 !important',
              },
            },
          },
          '&__question': {
            borderBottom: `1px solid ${secondaryMain} !important`,
            marginBottom: '0!important',
            padding: '11px 0!important',
            '@media (max-width: 1024px)': {
              padding: '8px 0!important',
            },
            '&:hover, &:focus': {
              background: 'none !important',
            },
            '&[aria-expanded=true]': {
              color: `${black} !important`,
              background: 'none!important',
              borderColor: 'transparent !important',
              '&>.MuiButton-label:after': {
                transform: 'rotate(-180deg)',
              },
            },
            '&>.MuiButton-label': {
              letterSpacing: '1.8px!important',
              '&>p': {
                '&:before': {
                  content: '"• "',
                },
              },
              '&:after': {
                content: '""',
                width: 35,
                height: 35,
                background:
                  'url("./images/maje/icon_arrow_down.svg") no-repeat 50% 50%',
                display: `none ${imp} `,
              },
              '@media (max-width: 1024px)': {
                fontSize: '10px !important',
                letterSpacing: '1.2px!important',
                '&:after': {
                  content: '""',
                  width: 10,
                  height: 7,
                  backgroundSize: 'contain !important',
                },
              },
            },
          },
          '&__btn': {
            border: '0!important',
            color: `${primaryMain} !important`,
          },
          '&__answer': {
            fontWeight: '500 !important',
            borderColor: 'transparent !important',
            borderBottom: `1px solid ${secondaryMain} !important`,
            lineHeight: 2,
            letterSpacing: '1px',
            padding: '0 16px 12px !important',
            '@media (max-width: 1024px)': {
              fontSize: '9px !important',
              padding: '0 11px 12px !important',
            },
          },
        },
        '.section-faq': {
          '& .MuiContainer-root': {
            paddingLeft: 9,
            paddingRight: 9,
            '@media (min-width: 1024px)': {
              paddingLeft: 0,
              paddingRight: 0,
              maxWidth: '1070px !important',
            },
            '& .MuiContainer-root': {
              paddingLeft: 0,
              paddingRight: 0,
              maxWidth: '1070px !important',
            },
          },
          '&__cta': {
            marginTop: '24px !important',

            '& p': {
              margin: '0px !important',
            },
          },
        },
        '#menu-toggler': {
          borderRadius: 0,
          height: `20px ${imp}`,
          width: `20px ${imp}`,
          marginLeft: `16px ${imp}`,
          '& .MuiButton-label': {
            top: `-1px ${imp}`,
          },
          '& .cart-badge': {
            display: `flex ${imp}`,
            alignItems: `center ${imp}`,
            justifyContent: `center ${imp}`,
            textAlign: `center ${imp}`,
            letterSpacing: `normal ${imp}`,
            background: `${black} ${imp}`,
            color: `${white} ${imp}`,
            border: `0 ${imp}`,
            padding: `0 ${imp}`,
            minWidth: `16px ${imp}`,
            paddingTop: `2px ${imp}`,
            height: `16px ${imp}`,
            lineHeight: `16px ${imp}`,
            borderRadius: `8px ${imp}`,
            right: `-8px ${imp}`,
            bottom: `-8px ${imp}`,
            fontSize: `10px ${imp}`,
            fontWeight: `400 ${imp}`,
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {
            '@media (min-width: 1024px)': {
              //marginTop: '16px !important',
            },
          },
        },
        '.cart-count': {
          color: `${black}!important`,
          fontSize: '15px!important',
          letterSpacing: '1.5px !important',
        },
        '.cart-reset': {
          ...title10,
          textTransform: 'none !important',
          textDecoration: 'underline !important',
          '&:hover, &:focus': {
            background: `none !important`,
          },
        },
        '[data-testid="continue"]': {
          padding: '9px 10px !important',
          fontSize: '11px !important',
          letterSpacing: '1.1px !important',
          minWidth: '160px !important',
          //background: `${primaryMain} !important`,
          //color: `${white} !important`,
          ...buttonClassic,
          '&:hover, &:focus': {
            //background: `${primaryDark} !important`,
          },
        },
        '.basket': {
          minHeight: `0 ${imp}`,
          '&__title, &-product__total': {
            fontWeight: '600 !important',
            fontSize: '12px!important',
            color: `${black} !important`,
          },
          '&__variant': {
            fontWeight: '600 !important',
            color: `${black} !important`,
          },
          '&-product__total': {
            color: `${black} !important`,
          },
          '&__time, &__time > span': {
            ...title10,
          },
          '&__icon': {
            height: `19px ${imp}`,
            width: `auto ${imp}`,
          },
        },
        '.page--home .footer, .page--faq .footer': {
          '@media (min-width: 1366px)': {
            paddingTop: 40,
          },
        },
        '.footer': {
          '&__topbtn': {
            '@media (min-width: 1366px)': {
              minWidth: '160px !important',
            },
          },
          '&__top': {
            background: 'none!important',
            '& .MuiGrid-container': {
              justifyContent: 'flex-end',
              '@media (max-width: 1024px)': {
                justifyContent: 'center',
              },
              '&> .MuiGrid-item': {
                maxWidth: 'none',
                flexBasis: 'auto',
                '@media (max-width: 1024px)': {
                  '&>a': {
                    marginTop: 0,
                  },
                },
              },
            },
            '& .MuiTypography-root': {
              ...title12,
              textTransform: 'uppercase !important',
              color: black,
              letterSpacing: '1.8px',
              textAlign: 'right',
              fontWeight: 500,
              '& p': {},
              '@media (max-width: 1024px)': {
                textAlign: 'center',
              },
            },
          },
          '&__bottom': {
            backgroundColor: `${primaryLight} !important`,
            '&>.MuiContainer-root': {
              '@media (min-width: 1024px)': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            },
            '@media (max-width: 1024px)': {
              padding: '30px 0 24px !important',
            },
          },
          '&__logo': {
            margin: 'auto!important',
            maxHeight: '150px!important',
            '@media (max-width: 1024px)': {
              maxHeight: '28px!important',
            },
          },
          '&__bottomRow1': {
            '@media (min-width: 1024px)': {
              marginTop: -10,
            },
          },
          '&__bottomRow2': {
            '@media (min-width: 1024px)': {
              marginTop: '0 !important',
              marginLeft: '40px !important',
            },
            '@media (max-width: 1024px)': {
              marginTop: '12px !important',
            },
          },
          '&__bottomRow1Col:empty': {
            display: 'none!important',
          },
          '&__bottomRow2Col': {
            '&:nth-child(1)': {
              display: 'none!important',
            },
            '&:nth-child(2)': {
              '@media (max-width: 1024px)': {
                textAlign: 'center !important',
                marginBottom: '0!important',
                '& a': {
                  display: 'block',
                  margin: '14px 0 !important',
                  padding: '4px 8px!important',
                  '&:last-child': {
                    // fontWeight: '700 !important',
                    marginBottom: '0!important',
                  },
                },
              },
              '& a': {
                fontSize: '10px!important',
                letterSpacing: '1.5px',
                fontWeight: 500,
                '&+a': {
                  '@media (min-width: 1366px)': {
                    marginLeft: '70px !important',
                  },
                },
              },
            },
          },
          '&__copyright': {
            backgroundColor: `${primaryLight} !important`,
          },
        },
        '.hero--shop': {
          '@media (max-width: 1024px)': {
            minHeight: '120px!important',
          },
          '& .MuiContainer-root': {
            '@media (max-width: 1024px)': {
              minHeight: '75px!important',
            },
          },
          '@media (min-width: 1366px)': {
            minHeight: '173px!important',
            '& .MuiContainer-root': {
              minHeight: '123px!important',
              justifyContent: 'center !important',
            },
          },
          '& .hero__toptitle': {
            marginTop: -10,
            marginBottom: 24,
            '@media (max-width: 1024px)': {
              marginBottom: 8,
              //position: 'absolute !important',
              //top: 2,
              //left: 16,
              fontSize: '7.5px !important',
            },
          },
          '& .hero__title': {
            fontWeight: 600,
            letterSpacing: '3.3px',
            textTransform: 'uppercase ! important',
            '@media (min-width: 1024px)': {
              fontSize: '32px !important',
            },
          },
        },
        // '.filter': {
        //   '&:hover': {
        //     backgroundColor: `${primaryMain}!important`,
        //   },
        //   '&.active': {
        //     color: `${black}!important`,
        //     '&:hover': {
        //       backgroundColor: `${primaryDark}!important`,
        //     },
        //   },
        // },
        '.filter__list_item--active': {
          fontWeight: '500 !important',
        },
        '.productCategories': {
          '& .MuiTabs-scroller': {
            '& button': {
              borderRadius: '0!important',
              textTransform: 'uppercase !important',
              '@media (max-width: 1024px)': {
                minHeight: 26,
              },
              '& .MuiTab-wrapper': {
                position: 'relative',
                top: 1,
              },
              '& + button': {
                '@media (min-width: 1366px)': {
                  marginLeft: 30,
                },
              },
              '&[aria-selected=true]': {
                borderBottomColor: primaryMain,
              },
            },
          },
        },
        '.sticky-bar': {
          '& .filters-menu': {
            paddingBottom: '0 !important',
          },
          '& .onlyAvailable': {
            marginTop: '0 !important',
          },
          '&--sticked': {
            '@media (max-width: 1024px)': {
              top: '52px !important',
              '&.sticky-bar--scrollup': {
                top: '0 !important',
              },
            },
          },
          '&--shop': {
            paddingBottom: '0!important',
          },
        },
        '.onlyAvailable, .filters--inline': {
          '&:before': {
            content: '""',
            position: 'absolute',
            width: '100vw',
            transform: 'translateX(-50%) translateY(0)',
            left: '50%',
            bottom: 0,
            borderBottom: '1px solid #E2E2E2',
          },
        },
        '.filters': {
          '&--inline': {
            marginLeft: '0 !important',
            position: 'relative',
            '&>div:first-child': {
              display: 'none',
            },
            '@media (max-width: 1024px)': {
              marginTop: '0 !important',
            },
          },
          '&__dropdown': {
            ...title11,
            border: '0 !important',
            color: `${black} !important`,
          },
        },
        '.onlyAvailable': {
          '&__check': {
            color: `${black} !important`,
          },
          '&__label': {
            ...title11,
            textTransform: 'none !important',
          },
        },
        '.funnel': {
          '&__header': {
            minHeight: '54px !important',
            '& .MuiTypography-root': {
              ...title14,
              fontSize: '21px !important',
              letterSpacing: '1px !important',
              color: `${white} !important`,
              position: 'relative',
              top: 2,
            },
          },
        },
        '.commitment': {
          paddingTop: '28px !important',
          paddingBottom: '28px !important',
          background: '#F2F2F2 !important',
          borderRadius: '4px !important',
          '&__title': {
            ...title14,
            textTransform: 'none! important',
            marginBottom: '10px!important',
          },
          '&__desc': {
            ...title10,
          },
          '&__text': {
            textAlign: 'center !important',
          },
        },
        '.payment-coupon, .payment-bycard': {
          width: 'auto !important',
          '@media (min-width: 1366px)': {
            width: '60% !important',
          },
        },
        '.payment-coupon': {
          '& .MuiGrid-root.MuiGrid-container': {
            //justifyContent: 'center',
          },
        },
        '.payment-bycard': {
          '& .MuiCheckbox-root + .MuiFormControlLabel-label .MuiTypography-root':
            {
              ...title10,
              lineHeight: '1.5 !important',
            },
        },
        '.invoice--download__icon': {
          display: 'none',
        },
        '.payment, .information, .shipping, .account, .faq-page, .contact-page, .confirmation, .takeBack':
          {
            background: `${primaryLight} !important`,
            '@media (min-width: 1024px)': {
              paddingTop: '42px !important',
              paddingBottom: '42px !important',
            },
          },
        '.order__buy': {
          marginBottom: '14px !important',
        },
        '.account, .account-infos, .contact-page': {
          '&__title': {
            '&.MuiTypography-root': {
              color: `${primaryMain} !important`,
              '@media (min-width: 1024px)': {
                ...title32,
                marginBottom: '8px !important',
              },
            },
          },
          '&-card': {
            '@media (min-width: 1024px)': {
              padding: '48px 50px!important',
            },
            '& .account__fields': {
              padding: '12px 0 !important',
              '@media (min-width: 1024px)': {
                padding: '48px 0 !important',
              },
            },
          },
          '&__switch, &__forgot': {
            ...title10,
            textDecoration: 'underline !important',
          },
          '&__switch': {
            textTransform: 'uppercase !important',
          },
          '&__desc': {
            maxWidth: 300,
            marginLeft: 'auto !important',
            marginRight: 'auto !important',
            textAlign: 'center !important',
            '&>b': {
              fontWeight: '500!important',
            },
          },
        },
        '.account': {
          '&__title': {
            '&.MuiTypography-root': {
              '@media (min-width: 1024px)': {
                textTransform: 'capitalize !important',
              },
            },
          },
          '&__bottom-text': {
            textAlign: 'center',
          },
        },
        '.account-infos': {
          '&__title': {
            '&.MuiTypography-root': {
              color: `${primaryMain} !important`,
              '@media (min-width: 1366px)': {
                textAlign: 'center !important',
              },
            },
          },
        },
        '.register-card': {
          '@media (max-width: 1024px)': {
            width: 'auto !important',
            margin: '0 16px !important',
          },
          '@media (min-width: 1024px)': {
            width: '766px !important',
            padding: '40px 90px 50px !important',
          },
        },
        '.login-card': {
          maxWidth: '436px !important',
          '@media (min-width: 1024px) and (min-height: 833px)': {
            marginTop: '62px !important',
            marginBottom: '62px !important',
          },
          '& .commitment': {
            display: 'none!important',
          },
        },
        '.forgot-card': {
          maxWidth: '565px !important',
        },
        '.faq-page': {
          '&__hero': {
            maxWidth: '1200px !important',
          },
          '&__title': {
            ...title32,
          },
          '&__cat': {
            '&.MuiTypography-root': {
              fontSize: '20px!important',
              letterSpacing: '2px !important',
              textAlign: 'center !important',
              paddingTop: 70,
            },
          },
        },
        '.faq-content': {
          maxWidth: '1035px !important',
        },
        '.link-card': {
          color: black,
          '@media (min-width: 1024px)': {
            margin: 25,
          },
          '&__title': {
            ...title14,
            textTransform: 'none !important',
            '&>*': {
              ...title14,
              textTransform: 'none !important',
            },
          },
        },
        '.contact-page ,.confirmation': {
          '@media (max-width: 1024px)': {
            padding: '0 !important',
          },
        },
        '.account-informations': {
          '& .MuiButton-outlinedPrimary': {
            minWidth: 160,
            background: `${primaryMain} !important`,
            color: `${white} !important`,
            border: `0 !important`,
            '&:hover, &:focus': {
              background: `${primaryDark} !important`,
            },
            '& .MuiButton-endIcon.MuiButton-iconSizeSmall, & .MuiSvgIcon-root':
              {
                display: 'none',
              },
          },
          '&__title': {
            color: `${black} !important`,
          },
        },
        '.page': {
          //overflowX: 'hidden',
          height: 'auto !important',
          minHeight: '100vh',
          background: `${white} !important`,
          '&__hero': {
            color: primaryMain,
            background: primaryLight,
            textAlign: 'center',
            maxWidth: '100% !important',
            padding: '0 !important',
            textTransform: 'uppercase !important',
            paddingBottom: '35px !important',
            marginBottom: '35px !important',
            '& .MuiTypography-root': {
              letterSpacing: '3px !important',
              fontWeight: '700 !important',
            },
            '@media (min-width: 1024px)': {
              paddingBottom: '75px !important',
              marginBottom: '55px !important',
            },
          },
          '&-content': {
            '@media (max-width: 1024px)': {
              paddingTop: 0,
              '& section:first-child': {
                '& #majelocation': {
                  ...titleWithLogo,
                },
              },
              '& section:nth-child(n+2)': {
                '& .page-wysiwyg > img': {
                  maxWidth: 'none!important',
                  width: '100vw',
                  position: 'relative',
                  transform: 'translateX(-50%) translateY(0)',
                  left: '50%',
                },
              },
            },
            '& #section-columns-1': {
              '@media (min-width: 1440px)': {
                paddingTop: 70,
              },
            },
            '& #section-columns-1 #column-2': {
              '@media (min-width: 1024px)': {
                paddingTop: 148,
              },
            },
          },
          '&__container': {
            '@media (max-width: 768px)': {
              maxWidth: 327,
            },
            '@media (min-width: 1024px)': {
              maxWidth: 1142,
            },
            '&--columns': {
              '@media (min-width: 1024px)': {
                maxWidth: '1142px !important',
              },
              '& .MuiGrid-spacing-xs-3': {
                '@media (min-width: 1024px)': {
                  margin: -25,
                  width: 'calc(100% + 50px)',
                  '&>.MuiGrid-item': {
                    padding: 25,
                  },
                },
              },
              '& .page-wysiwyg': {
                '& >img': {
                  width: '30% !important',
                },
                '& *': {
                  marginBottom: '25px !important',
                },
                '& h2': {
                  fontSize: 22,
                  lineHeight: 1.18,
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  wordSpacing: '3px',
                  color: black,
                  '&>strong': {
                    fontWeight: 700,
                  },
                },
                '& p': {
                  lineHeight: 1.58,
                  letterSpacing: 'normal',
                  '& strong': {
                    color: black,
                    fontWeight: 600,
                  },
                },
              },
            },
          },
          '&-wysiwyg': {
            '& > *': {
              lineHeight: 2.4,
              marginBottom: '55px !important',
            },
            '& > img': {
              maxWidth: 650,
            },
            '& b, & strong, & h1, & h2, & h3': {
              color: primaryMain,
            },
            '& em': {
              fontSize: 9,
              fontStyle: 'normal',
            },
          },
          '&-carousel': {
            '&__title': {
              ...title14,
              marginBottom: '18px !important',
              '@media (min-width: 1024px)': {
                marginLeft: '48px !important',
              },
            },
            '&-slide': {
              '&__content': {
                position: 'static!important',
                padding: '16px 0 !important',
                background: 'none !important',
                color: `${black} !important`,
                textAlign: 'left!important',
                ...title11,
                '& .MuiTypography-root': {
                  color: `${black} !important`,
                },
              },
            },
          },
        },
        '.page__hero--YMiuKBEAACIAmHHw': {
          '@media (max-width: 1024px)': {
            display: 'block !important',
            paddingBottom: '50px !important',
            marginBottom: '0!important',
            '& .MuiBox-root': {
              paddingTop: '25px !important',
            },
            '& .MuiTypography-root': {
              fontSize: '27px !important',
              letterSpacing: '2.7px !important',
              '&:before': {
                display: 'none !important',
              },
            },
          },
        },
        '.page__content--YMiuKBEAACIAmHHw': {
          '@media (max-width: 1024px)': {
            paddingTop: '32px !important',
          },
          '& .page__container--widget': {
            marginBottom: 68,
            '@media (max-width: 1024px)': {
              marginBottom: 45,
            },
          },
          '& .page__container--columns': {
            maxWidth: '1200px !important',
            marginTop: -45,
            '@media (max-width: 1024px)': {
              marginTop: -32,
            },
          },
          '& .page__container--carousel': {
            maxWidth: 1400,
            marginTop: 82,
            '@media (max-width: 1024px)': {
              marginTop: 45,
            },
          },
          '& .page-wysiwyg': {
            '& > *': {
              lineHeight: 1.6,
              marginBottom: '12px !important',
            },
            '& b, & strong, & h1, & h2, & h3': {
              color: black,
            },
            '& h1': {
              '@media (min-width: 1024px)': {
                fontSize: '34px !important',
                letterSpacing: '3.4px !important',
                marginBottom: '28px !important',
              },
            },
            '& h2': {
              fontWeight: '400 !important',
              '& strong': {
                fontWeight: '700 !important',
              },
              '@media (min-width: 1024px)': {
                fontSize: '20px !important',
                letterSpacing: '2px !important',
                marginBottom: '28px !important',
              },
            },
            '& em': {
              fontSize: 9,
              fontStyle: 'normal',
            },
          },
        },
        '.tryout': {
          '& [data-testid="btn-addToCart"]': {
            ...buttonPriority,
          },
          '&__btn': {
            color: 'black !important',
            border: `${primaryMain} 1px solid !important`,
            padding: '12px !important',

            '& .price__amount,& .price__duration,': {
              color: `${black} !important`,
            },
          },
        },
        '.spck': {
          '&-list': {
            '.page &': {
              '& *': {
                fontFamily: "'AvtGarde', sans-serif!important",
              },
            },
          },
          '&-shop-info': {
            '.page &': {
              paddingTop: '34px !important',
            },
          },
          '&-watch-button': {
            '.page &': {
              borderRadius: '0 !important',
              textTransform: 'uppercase !important',
              height: '45px !important',
            },
          },
          '&-button-paragraph': {
            '.page &': {
              fontSize: '12px !important',
              letterSpacing: '.9px !important',
              fontWeight: '600 !important',
            },
          },
          '&-event-date': {
            '.page &': {
              fontSize: '11px !important',
              fontWeight: '700 !important',
            },
          },
          '&-event-title': {
            '.page &': {
              fontSize: '18px !important',
              textTransform: 'uppercase !important',
              letterSpacing: '2.5px !important',
              fontWeight: '700 !important',
              marginTop: '10px !important',
            },
          },
          '&-event-description': {
            '.page &': {
              marginTop: '10px !important',
              textAlign: 'left !important',
            },
          },
        },

        '.modal': {
          '&>div[aria-hidden=true]': {
            background: 'rgba(0, 0, 0, 0.37) !important',
          },
          '&__close': {
            width: '64px !important',
            height: '64px !important',
            top: '0 !important',
            right: '0 !important',
            borderRadius: '0 !important',
            color: `${white} !important`,
            background: 'rgba(0, 0, 0, 0.48) !important',
          },
          '&__container': {
            maxWidth: '1112px !important',
            outline: 'none!important',
          },
          '&__content': {
            padding: '0!important',
          },
        },
        '.price': {
          '&__amount,&__duration': {
            color: `${primaryMain} !important`,
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 5,
        padding: '14px 32px',
        '&:hover, &:focus': {
          //backgroundColor: white,
          //border: `1px solid ${primaryMain}`,
        },
        fontWeight: 500,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        fontSize: 12,
        padding: '14px 32px',
      },
      label: {
        position: 'relative',
        top: 2, //top: 2 précédemment
        textAlign: 'center',
        '& .MuiSvgIcon-root': {
          position: 'relative',
          top: -1, //était à top: -2 (sans comprender pourquoi)
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus': {
          boxShadow: 'none',
          // backgroundColor: `${white}!important`,
          // border: `1px solid ${primaryMain}`,
        },
      },
      containedPrimary: {
        ...buttonClassic,
      },
      containedSecondary: {
        ...buttonClassic,
      },
      outlinedPrimary: {},
      textPrimary: {
        color: white,
      },
      textSecondary: {
        color: white,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)!important',
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: '0 0 0 1px #F4F4F4!important',
        '&:before': {
          backgroundColor: ` ${secondaryMain} !important`,
        },
      },
    },
    MuiAccordionSummary: {
      expandIcon: {
        color: ` ${primaryMain} !important`,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        color: `${white}!important`,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: '#000',
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: '#2933E7',
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: '#E54C80',
        backgroundColor: 'transparent',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: 13,
        lineHeight: 1.6,
        letterSpacing: '1.1px',
        borderRadius: '5px !important',
        padding: '14px 32px',
        ...buttonClassic,
        '& span': {
          position: 'relative',
          top: 2, //top: 2 précédemment
        },
      },
      // '&$.MuiSelected': {
      //           buttonClassic,
      // }
    },
  },
})

export default theme
